@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Voltaire&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind+Vadodara&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgba(0,0,0,0);
}

*::-webkit-scrollbar
{
	width: 6px;
	background-color: rgba(0,0,0,0);
}

*::-webkit-scrollbar-thumb
{
	background-color: rgb(224,224,224);
}

.mx-5::-webkit-scrollbar
{
	width: 6px;
	background-color: rgba(0,0,0,0);
}

.mx-5::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #FFF;
}

.font-alata {
	font-family: 'Alata', sans-serif;
}

.font-voltaire {
	font-family: 'Voltaire';
}

.font-poppins {
	font-family: 'Poppins';
}
